export const INITIAL_FIELDS = {
  name: '',
  nationality: true,
  country: '',
  id_number: '',
  gender: true,
  birthday: '',
  age: 16,
  disability: false,
  // permanent_address_code: '',
  // permanent_address_city: '',
  // permanent_address_detail: '',
  // residence_address_code: '',
  // residence_address_city: '',
  // residence_address_detail: '',
  phone_home: '',
  phone: '',
  email: '',
  line: '',
  height: 0,
  weight: 0,
  military_service_state: '未役',
  driver_license: '',
  notifiable_disease: false,
  highest_education_level: '',
  highest_education_state: true,
  highest_education_name: '',
  highest_education_department: '',
  highest_education_grade: 2,
  education_experience: '',
  emergency_contact: '',
  emergency_contact_relationship: '',
  emergency_contact_phone: '',
  family_member_1_name: '',
  family_member_1_contact: '',
  family_member_2_name: '',
  family_member_2_contact: '',
  company_1_name: '',
  company_1_position: '',
  company_1_duration: [],
  company_2_name: '',
  company_2_position: '',
  company_2_duration: [],
  company_3_name: '',
  company_3_position: '',
  company_3_duration: [],
  language_english: '',
  language_taiwanese: '',
  language_japanese: '',
  language_spanish: '',
  language_korean: '',
  language_german: '',
  language_french: '',
  language_thai: '',
  language_vietnamese: '',
  language_malay: '',
  language_other_name: '',
  language_other_level: '',
  ability_computer: true,
  ability_computer_name: '',
  ability_chinese_typewriting: '',
  ability_english_typewriting: '',
  ability_english_certification: false,
  ability_english_certification_name: [],
  aboard1: false,
  aboard1_time: [],
  aboard1_country: '',
  aboard1_major: '',
  aboard2: false,
  aboard2_time: [],
  aboard2_country: '',
  aboard2_major: '',
  recent_status: '',
  recent_status_others: '',
  diet: true,
  diet_note: '',
  formal_wear: true,
  formal_wear_note: '',
  bank_account: '',
  publish: false,
};

export const REQUIRD_FIELDS = [
  'name',
  'id_number',
  'nationality',
  'gender',
  'birthday',
  'phone_home',
  'phone',
  'email',
  'highest_education_level',
  'highest_education_state',
  'highest_education_name',
  'highest_education_department',
  'highest_education_grade',
  'emergency_contact',
  'emergency_contact_relationship',
  'emergency_contact_phone',
  'diet',
  'formal_wear',
];

export const FILE_UPLOAD_FIELDS = [
  'disability_credentials',
  'avatar',
  'ability_computer_files',
  'ability_english_certification_files',
  'aboard_files',
  'bank_passbook_file',
  'contract_file',
  'id_number_front_file',
  'id_number_back_file',
  'work_permit_front_file',
  'work_permit_back_file',
  'legal_agent_file',
  'custom_resume_file',
];

export const SEARCH_FIELDS = {
  gender: '',
  nationality: '',
  STAFF_LABELS: [],
};
