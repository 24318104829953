import React from 'react';
import styled from 'styled-components';
import {Tabs, Tag} from 'antd';
import moment from 'moment';

import {
  UserOutlined,
  BookOutlined,
  MoneyCollectOutlined,
} from '../../Widgets/Icon';
import {RepublicEraYearTransfer} from '../../Utils/index';
import {ImageDisplayField} from './MemberResume.ImageField';
import {ImagesDisplayField} from './MemberResume.ImagesField';

function ResumeDetail(props) {
  const {record} = props;
  const [selectTabKey, setSelectTabKey] = React.useState('1');

  if (!record) {
    return null;
  }

  return (
    <Wrapper>
      <div className="avatar-container">
        {record.avatar ? (
          <BgImage url={record.avatar} />
        ) : (
          <BgImage>
            <UserOutlined color="#FFFFFF" size={80} />
          </BgImage>
        )}
      </div>

      <Tabs
        defaultActiveKey={selectTabKey}
        size="large"
        onChange={(activeKey) => setSelectTabKey(activeKey)}>
        <Tabs.TabPane
          key="1"
          tab={
            <>
              <BookOutlined
                size={16}
                color={selectTabKey === '1' ? '#E18135' : '#808080'}
              />
              <span
                style={{
                  color: selectTabKey === '1' ? '#E18135' : '#808080',
                }}>
                我的履歷
              </span>
            </>
          }>
          <ResumeDetailContent record={record} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <>
              <MoneyCollectOutlined
                size={16}
                color={selectTabKey === '2' ? '#E18135' : '#808080'}
              />
              <span
                style={{
                  color: selectTabKey === '2' ? '#E18135' : '#808080',
                }}>
                行政項目
              </span>
            </>
          }>
          <AdministrationContent record={record} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .avatar-container {
    margin-bottom: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BgImage = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => (props.url ? '' : 'lightgray')};
  background-image: url(${(props) => props.url || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

function ResumeDetailContent(props) {
  let {record} = props;

  function getLanguageLevel(value) {
    switch (value) {
      case '':
        return '無';
      case 'little':
        return '略懂';
      case 'medium':
        return '普通';
      case 'high':
        return '中上';
      case 'master':
        return '精通';
      default:
        return value;
    }
  }

  return (
    <ContentWrapper>
      <section>
        <h3>基本資訊</h3>
        <div className="field">
          <label>
            <span className="required">*</span>姓名：
          </label>
          <div>{record.name}</div>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>國籍：
          </label>
          <span>{record.nationality ? '本國籍' : '外國籍'}</span>
        </div>

        <div className="field">
          {/* FIX ME: 身分證證字號、居留證號、護照號碼 */}
          <label>
            <span className="required">*</span>身分證字號：
          </label>
          <span>{record.id_number}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>性別：
          </label>
          <span>{record.gender ? '男' : '女'}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>出生日期：
          </label>
          <span>{record.birthday}</span>
          <span style={{paddingLeft: 5, fontSize: 12}}>
            {record.birthday && RepublicEraYearTransfer(record.birthday)}
          </span>
        </div>

        <div className="field">
          <label>年齡：</label>
          <span>{record.age}</span>
        </div>

        <div className="field">
          <label>是否為身心障礙者：</label>
          <span>{record.disability ? '是' : '否'}</span>
        </div>

        <div className="field">
          <label>身心障礙人士證明：</label>
          <ImageDisplayField
            fieldname="disability_credentials"
            record={record}
          />
        </div>

        <div className="field">
          <label>戶籍地址：</label>
          <span>
            {record.permanent_address_code} {record.permanent_address_city}{' '}
            {record.permanent_address_detail}
          </span>
        </div>

        <div className="field">
          <label>現居地址：</label>
          <span>
            {record.residence_addresss_code} {record.residence_address_city}{' '}
            {record.residence_address_detail}
          </span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>室內電話：
          </label>
          <span>{record.phone_home}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>手機號碼：
          </label>
          <span>{record.phone}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>電子郵件：
          </label>
          <span>{record.email}</span>
        </div>

        <div className="field">
          <label>Line ID：</label>
          <span>{record.line}</span>
        </div>

        <div className="field">
          <label>身高：</label>
          <span>{record.height} 公分</span>
        </div>

        <div className="field">
          <label>體重：</label>
          <span>{record.weight} 公斤</span>
        </div>

        <div className="field">
          <label>兵役狀況：</label>
          <span>{record.military_service_state}</span>
        </div>

        <div className="field">
          <label>駕照：</label>
          <span>{record.driver_license}</span>
        </div>

        <div className="field">
          <label>有無法定傳染疾病：</label>
          <span>{record.notifiable_disease ? '是' : '否'}</span>
        </div>

        <div className="field">
          <label>主要工作區域：</label>
          <span>{record.location}</span>
        </div>

        <div className="field">
          <label>個人近期照片</label>
          <ImageDisplayField fieldname="avatar" record={record} />
        </div>
      </section>

      <section>
        <h3>學歷背景</h3>

        <div className="field">
          <label>
            <span className="required">*</span>最高學歷
          </label>
          <span>{record.highest_education_level}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>學歷狀況
          </label>
          <span>{record.highest_education_state ? '畢業' : '在學'}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀學校
          </label>
          <span>{record.highest_education_name}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀科系
          </label>
          <span>{record.highest_education_department}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀年級
          </label>
          <span>{record.highest_education_grade}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>學校經驗
          </label>
          <span>{record.education_experience}</span>
        </div>
      </section>

      <section>
        <h3>家庭背景</h3>

        <div className="field">
          <label>
            <span className="required">*</span>緊急聯絡人
          </label>
          <span>{record.emergency_contact}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>關係
          </label>
          <span>{record.emergency_contact_relationship}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>手機號碼
          </label>
          <span>{record.emergency_contact_phone}</span>
        </div>

        <div className="field">
          <label>家中成員1</label>
          <span>{record.family_member_1_name}</span>
        </div>

        <div className="field">
          <label>手機號碼</label>
          <span>{record.family_member_1_contact}</span>
        </div>

        <div className="field">
          <label>家中成員2</label>
          <span>{record.family_member_2_name}</span>
        </div>

        <div className="field">
          <label>手機號碼</label>
          <span>{record.family_member_2_contact}</span>
        </div>
      </section>

      <section>
        <h3>工作經驗</h3>

        <div className="field">
          <label>公司1名稱</label>
          <span>{record.company_1_name}</span>
        </div>

        <div className="field">
          <label>公司1職務</label>
          <span>{record.company_1_position}</span>
        </div>

        <div className="field">
          <label>任職期間</label>
          <span>
            {record.company_1_duration &&
              record.company_1_duration.map((date, index) => {
                if (index === 0) {
                  return `${moment(date).format('YYYY/MM')}-`;
                } else {
                  return moment(date).format('YYYY/MM');
                }
              })}
          </span>
        </div>

        <div className="field">
          <label>公司2名稱</label>
          <span>{record.company_2_name}</span>
        </div>

        <div className="field">
          <label>公司2職務</label>
          <span>{record.company_2_position}</span>
        </div>

        <div className="field">
          <label>任職期間</label>
          <span>
            {record.company_2_duration &&
              record.company_2_duration.map((date, index) => {
                if (index === 0) {
                  return `${moment(date).format('YYYY/MM')}-`;
                } else {
                  return moment(date).format('YYYY/MM');
                }
              })}
          </span>
        </div>

        <div className="field">
          <label>公司3名稱</label>
          <span>{record.company_3_name}</span>
        </div>

        <div className="field">
          <label>公司3職務</label>
          <span>{record.company_3_position}</span>
        </div>

        <div className="field">
          <label>任職期間</label>
          <span>
            {record.company_3_duration &&
              record.company_3_duration.map((date, index) => {
                if (index === 0) {
                  return `${moment(date).format('YYYY/MM')}-`;
                } else {
                  return moment(date).format('YYYY/MM');
                }
              })}
          </span>
        </div>

        <div className="field">
          <label>備註</label>
          <span>{record.company_note}</span>
        </div>
      </section>

      <section>
        <h3>語言能力</h3>

        <div className="field">
          <label>英文</label>
          <span>{record.language_english}</span>
        </div>

        <div className="field">
          <label>台語</label>
          <span>{record.language_taiwanese}</span>
        </div>

        <div className="field">
          <label>日語</label>
          <span>{record.language_japanese}</span>
        </div>

        <div className="field">
          <label>西語</label>
          <span>{record.language_spanish}</span>
        </div>

        <div className="field">
          <label>韓語</label>
          <span>{record.language_korean}</span>
        </div>

        <div className="field">
          <label>德語</label>
          <span>{record.language_german}</span>
        </div>

        <div className="field">
          <label>法語</label>
          <span>{record.language_french}</span>
        </div>

        <div className="field">
          <label>泰語</label>
          <span>{record.language_thai}</span>
        </div>

        <div className="field">
          <label>越南語</label>
          <span>{record.language_vietnamese}</span>
        </div>

        <div className="field">
          <label>馬來語</label>
          <span>{record.language_malay}</span>
        </div>

        <div className="field">
          <label>其他語言</label>
          <span>{record.language_other_name}</span>
        </div>

        <div className="field">
          <label>語言程度</label>
          <span>{record.language_other_level}</span>
        </div>

        <div className="field">
          <label>語言備註</label>
          <span>{record.language_note}</span>
        </div>
      </section>

      <section>
        <h3>其他能力</h3>

        <div className="field">
          <label>電腦能力</label>
          <span>{record.ability_computer ? '具備' : '不具備'}</span>
        </div>

        <div className="field">
          <label>證照名稱</label>
          <span>{record.ability_computer_name}</span>
        </div>

        <div className="field">
          <label>證照證明</label>
          <ImagesDisplayField
            fieldname="ability_computer_files"
            record={record}
          />
        </div>

        <div className="field">
          <label>中打能力</label>
          <span>{record.ability_chinese_typewriting} 字/分鐘</span>
        </div>

        <div className="field">
          <label>英打能力</label>
          <span>{record.ability_english_typewriting} 字/分鐘</span>
        </div>

        <div className="field">
          <label>英語檢定</label>
          <span>
            {record.ability_english_certification ? '具備' : '不具備'}
          </span>
        </div>

        <div className="field">
          <label>證照名稱</label>
          {record.ability_english_certification_name?.map((name) => (
            <span className="orange-label">{name}</span>
          ))}
        </div>

        <div className="field">
          <label>證照證明</label>
          <ImagesDisplayField
            fieldname="ability_english_certification_files"
            record={record}
          />
        </div>

        <div className="field">
          <label>其他專長</label>
          <span>{record.expertise}</span>
        </div>
      </section>

      <section>
        <h3>其他經驗</h3>

        <div className="field">
          <label>國外留學 / 工作經驗 1</label>
          <span>{record.aboard1 ? '具備' : '不具備'}</span>
        </div>

        <div className="field">
          <label>國外留學 / 工作時間 1</label>
          <span>
            {record.aboard1_time &&
              record.aboard1_time.map((time, index) => {
                if (index === 0) {
                  return `${moment(time).format('YYYY/MM')}-`;
                } else {
                  return moment(time).format('YYYY/MM');
                }
              })}
          </span>
        </div>

        <div className="field">
          <label>國外留學 / 工作國家 1</label>
          <span>{record.aboard1_country}</span>
        </div>

        <div className="field">
          <label>國外學習 / 工作項目 1</label>
          <span>{record.aboard1_major}</span>
        </div>

        <div className="field">
          <label>國外留學 / 工作經驗 2</label>
          <span>{record.aboard2 ? '具備' : '不具備'}</span>
        </div>

        <div className="field">
          <label>國外留學 / 工作時間 2</label>
          <span>
            {record.aboard2_time &&
              record.aboard2_time.map((time, index) => {
                if (index === 0) {
                  return `${moment(time).format('YYYY/MM')}-`;
                } else {
                  return moment(time).format('YYYY/MM');
                }
              })}
          </span>
        </div>

        <div className="field">
          <label>國外留學 / 工作國家 2</label>
          <span>{record.aboard2_country}</span>
        </div>

        <div className="field">
          <label>國外學習 / 工作項目 2</label>
          <span>{record.aboard2_major}</span>
        </div>

        <div className="field">
          <label>學習證明</label>
          <ImagesDisplayField fieldname="aboard_files" record={record} />
        </div>
      </section>

      <section>
        <h3>服裝餐飲詢問</h3>

        <div className="field">
          <label style={{width: 'auto', whiteSpace: 'pre', marginRight: 25}}>
            <span className="required">*</span>
            {'是否備有黑色正式裝\n'}
            <span style={{fontSize: 10}}>
              (白色長袖襯衫+黑褲/裙+黑西裝外套+黑皮鞋+黑娃娃鞋)
            </span>
          </label>
          <span>{record.formal_wear ? '是' : '否'}</span>
        </div>

        <div className="field">
          <label>服裝備註</label>
          <span>{record.formal_wear_note}</span>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>餐飲習慣
          </label>
          <span>{record.diet ? '葷' : '素'}</span>
        </div>

        <div className="field">
          <label>餐飲備註</label>
          <span>{record.diet_note}</span>
        </div>
      </section>

      <section>
        <h3>近況更新</h3>
        <div className="field">
          <label>目前近況</label>
          <span>{record.reecent_status}</span>
        </div>

        <div className="field">
          <label>請填寫其他描述</label>
          <span>{record.recent_status_others}</span>
        </div>
      </section>

      <section>
        <h3>其他</h3>

        <div className="field">
          <label>自製簡歷</label>
          <ImageDisplayField fieldname="custom_resume_file" record={record} />
        </div>

        <div className="field">
          <label>自選標籤</label>
          {record.user_labels?.map((label) => (
            <span className="orange-label">{label}</span>
          ))}
        </div>

        <div className="field">
          <label>人才天下活動得知管道</label>
          <span>{record.source}</span>
          <span>{record.source_note}</span>
        </div>
      </section>
    </ContentWrapper>
  );
}

function AdministrationContent(props) {
  let {record} = props;
  return (
    <ContentWrapper needMask={record.INTERVIEW_STATE !== 'pass'}>
      <div className="mask" />
      <div className="hint">
        {`# 提示 : 會員您好，因應完成活動後之相關發薪作業需要，煩請提供下列文件以利完成後續發薪作業。\n-「身分證或居留證正反面影本」\n-「郵局或中國信託本人台幣帳戶存摺封面影本」\n(如兩者皆無，務必提供其他家金融機構之存摺封面影本)\n\n# 注意事項 : ＊帳戶存摺封面需有分行資訊 ＊其他家金融機構另收手續費30元\n人才天下有限公司感謝您的配合！ ：）`}
      </div>
      <section>
        <div className="field">
          <label>郵局立帳局號</label>
          <span>{record.post_office_number}</span>
        </div>

        <div className="field">
          <label>郵局存簿帳號</label>
          <span>{record.post_office_account}</span>
        </div>

        <div className="field">
          <label>銀行解款行代號</label>
          <span>{record.bank_code}</span>
        </div>

        <div className="field">
          <label>銀行分行代號</label>
          <span>{record.bank_branch_code}</span>
        </div>

        <div className="field">
          <label>銀行別-分行名稱</label>
          <span>{record.bank_account_note}</span>
        </div>

        <div className="field">
          <label>銀行帳號</label>
          <span>{record.bank_account}</span>
        </div>

        <div className="field">
          <label>存摺封面照片</label>
          <ImageDisplayField fieldname="bank_passbook_file" record={record} />
        </div>

        <div className="field">
          <label>合約回簽照片</label>
          <ImageDisplayField fieldname="contract_file" record={record} />
        </div>

        <div className="field">
          <label>身份證、居留證正面照片</label>
          <ImageDisplayField fieldname="id_number_front_file" record={record} />
        </div>

        <div className="field">
          <label>身份證、居留證反面照片</label>
          <ImageDisplayField fieldname="id_number_back_file" record={record} />
        </div>

        <div className="field">
          <label>工作籤正面照片</label>
          <ImageDisplayField
            fieldname="work_permit_front_file"
            record={record}
          />
        </div>

        <div className="field">
          <label>工作簽反面照片</label>
          <ImageDisplayField
            fieldname="work_permit_back_file"
            record={record}
          />
        </div>

        <div className="field">
          <label>法定代理人證書回簽照片</label>
          <ImageDisplayField fieldname="legal_agent_file" record={record} />
        </div>
      </section>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  position: relative;

  & > .mask {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    display: ${(props) => (props.needMask ? 'block' : 'none')};
  }

  & > .hint {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 15px;
    white-space: pre;
    line-height: 20px;
    font-size: 12px;
    color: gray;
  }

  & > section {
    border-bottom: 1px solid lightgray;
    padding-top: 20px;
    padding-bottom: 20px;

    & > h3 {
      margin-bottom: 20px;
      font-size: 20px;
      color: #4a4a4a;
    }

    & > .field {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      min-height: 30px;
      font-size: 16px;
      color: #4a4a4a;

      & > label {
        margin-right: 5px;
        padding-right: 5px;
        font-weight: 400;

        & > .required {
          margin-right: 5px;
          color: #ff0000;
        }
      }

      & > span {
      }

      & > .orange-label {
        margin: 0px 10px 10px 0px;
        border-radius: 5px;
        padding: 5px 10px;

        background-color: #e18135;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
`;

export default ResumeDetail;
