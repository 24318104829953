import React from 'react';
import styled from 'styled-components';
import {Tabs, Table, Tag, Button, Modal, Input, Empty} from 'antd';
import moment from 'moment';

import * as AppContext from '../../AppContext';
import {
  CommentSearch,
  BellOutlined,
  UnorderedListOutlined,
} from '../../Widgets/Icon';
import Filter from './Filter';
import ResumeDisplayDetail from './MemberResume.DisplayDetail';
import InterviewDetail from './InterviewDetail';
import SignupDetail from './SignupDetail';
import EmailDetail from './EmailDetail';

function MemberMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const [search, setSearch] = React.useState('');
  const [records, setRecords] = React.useState(null);
  const [pendingRecords, setPendingRecords] = React.useState(null);
  const [selectedDetail, setSelectedDetail] = React.useState(null);

  const columns = [
    {title: '姓名', dataIndex: 'name', key: 'name'},
    {
      title: '性別',
      dataIndex: 'gender',
      key: 'gender',
      render: (_, record) => <div>{record.gender ? '男' : '女'}</div>,
    },
    {
      title: '年齡',
      dataIndex: 'age',
      key: 'age',
      render: (_, record) => <div>{record.age}歲</div>,
    },
    {
      title: '履歷',
      dataIndex: 'resume',
      align: 'center',
      key: 'resume',
      render: (_, record) => (
        <Button onClick={() => setSelectedDetail({record, type: 'resume'})}>
          履歷
        </Button>
      ),
    },
    {
      title: '面試狀態',
      dataIndex: 'INTERVIEW_STATE',
      key: 'INTERVIEW_STATE',
      align: 'center',
      render: (_, record) => {
        switch (record.INTERVIEW_STATE) {
          case 'pending':
            return <Tag color="magenta">未面試</Tag>;
          case 'sent':
            return <Tag color="purple">面試通知已寄出</Tag>;
          case 'confirmed':
            return <Tag color="cyan">確認面試</Tag>;
          case 'rescheduled':
            return <Tag color="geekblue">面試需改期</Tag>;
          case 'refused':
            return <Tag color="">已不需面試</Tag>;
          case 'pass':
            return <Tag color="green">合格</Tag>;
          case 'candidate':
            return <Tag color="blue">備取</Tag>;
          case 'unqualified':
            return <Tag color="">不合格</Tag>;
          default:
            return record.INTERVIEW_STATE;
        }
      },
    },
    {
      title: '面試評價',
      dataIndex: 'interview',
      key: 'interview',
      align: 'center',
      render: (_, record) => (
        <Button
          shape={'circle'}
          icon={<CommentSearch />}
          onClick={() => setSelectedDetail({record, type: 'interview'})}
        />
      ),
    },
    {
      title: '報名紀錄',
      dataIndex: 'signup',
      key: 'signup',
      align: 'center',
      render: (_, record) => (
        <Button
          shape={'circle'}
          icon={<UnorderedListOutlined />}
          onClick={() => setSelectedDetail({record, type: 'signup'})}
        />
      ),
    },
    {
      title: '發信',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => (
        <Button onClick={() => setSelectedDetail({record, type: 'email'})}>
          郵件選單
        </Button>
      ),
    },
    {
      title: '履歷遞交時間',
      dataIndex: 'update',
      key: 'update',
      render: (_, record) => {
        let updated = new Date(record.updated * 1000);
        let today = new Date();
        return `${moment(today).diff(updated, 'days')} 天前`;
      },
    },
  ];

  const staffGetMemberList = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let records = await app.actions.staffGetMemberList();
      setRecords(records.filter((record) => record.publish));
      setPendingRecords(
        records
          .filter((record) => record.publish)
          .filter((record) => record.INTERVIEW_STATE === 'pending'),
      );
    } catch (ex) {
      console.warn('staff fetch member list api error');
    }
    app.actions.setLoading(false);
  }, [app.actions]);

  const staffExportMembersCSV = React.useCallback(async () => {
    //FIX: this feature will support when phase II finished.
    // try {
    //   await app.actions.staffExportMembersCSV();
    // } catch (err) {
    //   console.warn(err);
    // }
  }, [app.actions]);

  const onSearch = async (value) => {
    console.log('value', value);
    if (!value) {
      return;
    }
    app.actions.setLoading(true);
    try {
      let records = await app.actions.staffSearchMemberByFields({name: value});
      setRecords(records.filter((record) => record.publish));
      setPendingRecords(
        records
          .filter((record) => record.publish)
          .filter((record) => record.INTERVIEW_STATE === 'pending'),
      );
    } catch (err) {
      throw err;
    }

    app.actions.setLoading(false);
  };

  const dismissModal = () => {
    setSelectedDetail(null);
  };

  React.useEffect(() => {
    staffGetMemberList();
  }, [staffGetMemberList, app.state.staff]);

  return (
    <Wrapper>
      <div className="header">
        <h1 className="title">會員管理</h1>
      </div>
      <div className="action-bar">
        <div>
          <Input.Search
            value={search}
            onChange={async (e) => {
              if (!e.target.value) {
                setSearch('');
                staffGetMemberList();
              } else {
                setSearch(e.target.value);
              }
            }}
            placeholder="搜尋姓名"
            style={{width: 250}}
            onSearch={onSearch}
            enterButton
          />
        </div>
        <Button
          type="primary"
          onClick={() => setSelectedDetail({type: 'filter'})}>
          設定篩選條件
        </Button>
        <div style={{flex: 1}} />
        <Button type="primary" onClick={() => staffExportMembersCSV()}>
          匯出會員清單
        </Button>
      </div>

      <Tabs type="card">
        <Tabs.TabPane
          tab={`未面試會員(${pendingRecords?.length || 0})`}
          key="1">
          {pendingRecords ? (
            <Table columns={columns} dataSource={pendingRecords} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`全部會員(${records?.length || 0})`} key="2">
          {records ? (
            <Table columns={columns} dataSource={records} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Tabs.TabPane>
      </Tabs>

      <Modal
        title={null}
        footer={null}
        width={760}
        bodyStyle={{
          padding:
            selectedDetail?.type === 'resume' ||
            selectedDetail?.type === 'email'
              ? 20
              : 0,
          maxHeight: 700,
          height: '100%',
          overflow: 'auto',
        }}
        visible={!!selectedDetail}
        onOk={dismissModal}
        onCancel={dismissModal}>
        {selectedDetail?.type === 'filter' && (
          <Filter
            onSetRecords={(records) =>
              setRecords(records.filter((record) => record.publish))
            }
            onSetPendingRecords={(records) =>
              setPendingRecords(
                records
                  .filter((record) => record.publish)
                  .filter((record) => record.INTERVIEW_STATE === 'pending'),
              )
            }
            onCancel={dismissModal}
          />
        )}

        {selectedDetail?.type === 'resume' && (
          <ResumeDisplayDetail
            key={selectedDetail?.record.owner}
            record={selectedDetail?.record}
          />
        )}

        {selectedDetail?.type === 'interview' && (
          <InterviewDetail
            key={selectedDetail?.record.owner}
            record={selectedDetail?.record}
            onRefresh={staffGetMemberList}
            onCancel={dismissModal}
          />
        )}

        {selectedDetail?.type === 'signup' && (
          <SignupDetail
            key={selectedDetail?.record.owner}
            record={selectedDetail?.record}
            onCancel={dismissModal}
          />
        )}

        {selectedDetail?.type === 'email' && (
          <EmailDetail
            key={selectedDetail?.record.email}
            record={selectedDetail?.record}
            onRefresh={staffGetMemberList}
            onCancel={dismissModal}
          />
        )}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > .title {
      font-size: 24px;
      color: #4a4a4a;
      line-height: 22px;
    }
  }

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    & > button {
      margin-left: 10px;
    }
  }
`;

export default MemberMgrPage;
