export const EMPTY_EMAIL_TEMPALTE = () => {
  return {
    title: '',
    content: '',
  };
};

export const INTERVIEW_EMAIL_TEMPLATE = (user, staff) => {
  return {
    title: '人才天下有限公司-活動面試邀約',
    content: `${user.name} ${
      user.gender ? '先生' : '小姐'
    }您好，\n\n我是人事專員，敝姓 ${staff.name.slice(
      0,
      1,
    )}。\n有關【活動】，感謝您的報名，我們對您過去的資經歷非常感興趣，並誠摯邀請您於【2021/03/11 下午三點半】至本公司進行面試。確認參加面試請按確認參加按鈕；若時間上無法配合，請您來電 02-2720-1610 分機 213，或來信至 bfhrs5b32@gmail.com 改約其他時間。\n\n* 提醒您：面試無須攜帶任何物品，服裝請穿著乾淨整齊即可。\n* 著正裝前來面試可協助檢查服裝儀容是否符合活動需求\n\n 面試地址:台北市信義區信義路五段5號(世貿一館) 5樓 F區23室\n\n以上如有任何問題，歡迎隨時與我們聯繫!\n感謝您的配合，並祝您一切順心!

    　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊 ${
      staff.name
    }`,
  };
};

export const ACCOUNT_CALL_EMAIL_TEMPLATE = (user, staff) => {
  return {
    title: '人才天下有限公司-提醒重要文件繳交上傳',
    content: `${user.name} ${
      user.gender ? '先生' : '小姐'
    }您好，\n\n首先感謝您的協助參與，使【xxx】活動圓滿順利完成，提醒您，相關所有需要繳交給本公司的文件(如下)，須請您再次檢視是否仍有遺漏，遺漏文件再麻煩請於【3/20 18:00】前上傳。\n
(一)健保保留原單位申請書(如附檔，列印後簽名拍照上傳)
(二)人才天下有限公司臨時人員派遣契約 (如附檔，列印後簽名拍照上傳)
(三)本人身分證正反面(拍照上傳，以利勞保及發薪作業使用)
(四)本人金融帳號存摺(台幣)影本(郵局or中國信託)，若提供他家金融機購則需另扣30元轉帳手續費(拍照或掃描上傳)
    
出勤當月的活動薪資將於活動結束隔月15日匯款，如遇例假日則順延至下一個工作日。
* 補充說明：如果您是提供郵局帳號，由於郵局需要時間處理薪資匯款作業，因此您的薪資將於隔日匯入，請於隔日再至郵局刷存簿確認!
    
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
    
    　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const RESUME_SAVE_ONLY_EMAIL_TEMPLATE = (user, staff) => {
  return {
    title: '面試錄取通知-僅先入資料庫',
    content: `${user.name} ${
      user.gender ? '先生' : '小姐'
    }您好，\n\n感謝您的報名與撥冗參與面試，由於目前尚無適合活動可以安排，我們先將您的資料建檔於本公司人才資料庫，待有最新工作機會時，我們將儘快通知您，並徵求您參與之意願與可配合的時間！
    
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const SIGNUP_QUALIFIED_EMAIL_TEMPLATE = (user, staff, activity) => {
  return {
    title: '人才天下有限公司-錄取與活動班表，收到請即刻回覆',
    content: `${user.name} ${
      user.gender ? '先生' : '小姐'
    }您好，\n\n我是人事專員，敝姓 ${staff.name.slice(0, 1)}。\n有關 ${
      activity.name
    }，感謝您的報名與撥冗參與面試，經本公司與客戶之相關評核後，恭禧您錄取為本活動之工作人員。
    
附件為本活動之班表，務必請您於耐心詳閱之後，於本信件按鈕回覆收到班表並確認相關資訊無誤。一旦確認班表之後，如有任何異動或對班表有任何疑義，務必請您來電或來信詢問。

提醒您，出勤前務必確認您的上班地點與時間，切勿遲到。到班前請記得先吃飽，保持充足睡眠，多喝水，工作時也請保持愉快的心情!工作崗位及時間以現場活動為主，隨時注意自身服裝儀容乾淨整齊，並且記得有空多補充水分! 隨時注意儀態及保持笑容!

還有用餐部分，如有吃素或服裝等問題，請於回覆時再備註一次喔!

另外，為因應新冠肺炎防治，配合政府防疫措施，請自行準備口罩並務必於上班時配戴口罩。出入任一公共場所，務必勤洗手與消毒，並於上班前確認體溫溫度，如有發燒或身體不適，請儘快告知現場活動或人事聯絡人。

以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!

　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const SIGNUP_CANDIDATE_NONE_INTERVIEW_EMAIL_TEMPLATE = (
  user,
  staff,
  activity,
) => {
  return {
    title: '人才天下有限公司-遺珠通知與致謝',
    content: `${user.name} ${user.gender ? '先生' : '小姐'}您好，\n\n有關 ${
      activity.name
    }，感謝您的報名，由於應徵人才眾多，然本活動所能提供的職位有限或有其他不相符合之處，恐有遺珠之憾，甚為可惜。我們已將您的資料建檔於本公司人才資料庫，並期望日後其他可能合作機會！
      
            
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
            
    　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const SIGNUP_CANDIDATE_HAVE_INTERVIEW_EMAIL_TEMPLATE = (
  user,
  staff,
  activity,
) => {
  return {
    title: '人才天下有限公司-遺珠通知與致謝(參與面試者)',
    content: `${user.name} ${user.gender ? '先生' : '小姐'}您好，\n\n有關 ${
      activity.name
    }，感謝您的報名與撥冗參與工作面試，由於應徵人才眾多，然本活動所能提供的職位有限或有其他不相符合之處，恐有遺珠之憾，甚為可惜。您在面試中展現之經驗、資歷及談吐皆留下很好的印象，我們已將您的資料建檔於本公司人才資料庫，並期望日後其他可能合作機會！
    
另外，如有其他工作人員主動放棄此工作機會或有臨時增加人力需求者，我們將儘快通知您，並徵求您再次參與之意願！
        
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
        
　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const SIGNUP_REFUSED_NONE_INTERVIEW_EMAIL_TEMPLATE = (
  user,
  staff,
  activity,
) => {
  return {
    title: '人才天下有限公司-遺珠通知與致謝',
    content: `${user.name} ${user.gender ? '先生' : '小姐'}您好，\n\n有關 ${
      activity.name
    }，感謝您的報名，由於應徵人才眾多，然本活動所能提供的職位有限或有其他不相符合之處，恐有遺珠之憾，甚為可惜。我們已將您的資料建檔於本公司人才資料庫，並期望日後其他可能合作機會！
          
                
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
                
        　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};

export const SIGNUP_REFUSED_HAVE_INTERVIEW_EMAIL_TEMPLATE = (
  user,
  staff,
  activity,
) => {
  return {
    title: '人才天下有限公司-遺珠通知與致謝(參與面試者)',
    content: `${user.name} ${user.gender ? '先生' : '小姐'}您好，\n\n有關 ${
      activity.name
    }，感謝您的報名與撥冗參與工作面試，由於應徵人才眾多，然本活動所能提供的職位有限或有其他不相符合之處，恐有遺珠之憾，甚為可惜。您在面試中展現之經驗、資歷及談吐皆留下很好的印象，我們已將您的資料建檔於本公司人才資料庫，並期望日後其他可能合作機會！
        
另外，如有其他工作人員主動放棄此工作機會或有臨時增加人力需求者，我們將儘快通知您，並徵求您再次參與之意願！
            
以上如有任何問題，歡迎隨時與我們聯繫!
感謝您的配合，並祝您一切順心!
            
    　　　　　　　　　　　　　　　　　　人才天下有限公司 人事管理團隊`,
  };
};
