const STAFF_LABELS = [
  '展覽',
  '活動',
  '會議',
  '採洽',
  '長期展',
  '門禁',
  '報到換證',
  '服務台',
  '售票',
  '收銀',
  '攤位接待',
  '機動人員',
  '舞台協助',
  '傳單問券',
  '舉牌宣傳',
  '引導帶位',
  '導覽',
  '司儀',
  'PG',
  'SG',
  'PB',
  'SB',
  '翻譯',
  '英文',
  '日文',
  '勞動力（進撤場)',
  '場控',
  '電腦控',
  '前台助理',
  '洽談桌長',
  '展區',
  '商售',
  '主持人',
  '辦公室助理',
  '活潑外向',
  '內向',
  '待注意',
  '紅標（勿用)',
];

const USER_LABELS = [
  '消費性展覽',
  '活動',
  '會議',
  '採洽',
  '長期主題展覽',
  '耐久站',
  '活潑外向',
  '文靜內斂',
  '認真細心',
  '收銀售票',
  '會議前台',
  '可長期配合',
  '短期打工',
  '不遲到',
];

export {STAFF_LABELS, USER_LABELS};
