import React from 'react';
import styled from 'styled-components';
import {Upload, Button, Spin} from 'antd';

import * as AppContext from '../../AppContext';
import {UploadOutlined} from '../../Widgets/Icon';

function ImageEditField(props) {
  const app = React.useContext(AppContext.Context);
  const {fieldname, record} = props;
  const [values, setValues] = React.useState({
    loading: false,
    url: null,
    data: {},
    filename: null,
  });

  const beforeUpload = async (file) => {
    try {
      const resp = await app.actions.memberCreateImageRequest(file);
      setValues({
        ...values,
        url: resp.url,
        data: resp.fields,
        filename: resp.expected,
      });
    } catch (err) {
      console.warn('upload file failed', err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setValues({...values, loading: true});
    }
    if (info.file.status === 'done') {
      try {
        await app.actions.memberUpdateProfile({
          ...record,
          [fieldname]: values.filename,
        });
      } catch (err) {
        console.warn('update profile failed', err);
      }

      setValues({...values, loading: false});
    }
  };

  return (
    <Wrapper>
      <Upload
        name="file"
        listType="picture container"
        showUploadList={false}
        action={values.url}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        data={values.data}>
        {values.loading ? (
          <Spin />
        ) : record[fieldname] ? (
          <BgImage url={record[fieldname]} needMoreHeight={true}>
            <Button shape="circle" icon={<UploadOutlined />} />
          </BgImage>
        ) : (
          <BgImage needMoreHeight={false}>
            <Button icon={UploadOutlined}>上傳圖檔</Button>
          </BgImage>
        )}
      </Upload>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const BgImage = styled.div`
  width: 100px;
  height: ${(props) => (props.needMoreHeight ? 100 : 32)}px;
  border-radius: 10px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${(props) => props.url || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  & button {
    position: absolute;
    right: 0px;
    bottom: -4px;
  }
`;

function ImageDisplayField(props) {
  let {fieldname, record} = props;
  return (
    <Wrapper>
      {record[fieldname] && (
        <a target="_blank" rel="noreferrer" href={record[fieldname]}>
          <BgImage url={record[fieldname]} needMoreHeight={true} />
        </a>
      )}
    </Wrapper>
  );
}

export {ImageEditField, ImageDisplayField};
