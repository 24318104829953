import {REQUIRD_FIELDS} from '../../content/resume';

const onValidateResumeRequiredFields = (values) => {
  console.log('hi');
  let result = [...REQUIRD_FIELDS];
  result = result.filter((key) => {
    if (values[key] === '') {
      return false;
    } else {
      return true;
    }
  });

  if (result.length === REQUIRD_FIELDS.length) {
    return true;
  }

  return false;
};

export {onValidateResumeRequiredFields};
