import React from 'react';
import styled from 'styled-components';
import {Upload, Button, Spin} from 'antd';

import * as AppContext from '../../AppContext';
import {UploadOutlined} from '../../Widgets/Icon';

function ImagesEditField(props) {
  const app = React.useContext(AppContext.Context);
  const {fieldname, record} = props;
  const [files, setFiles] = React.useState(
    record[fieldname] ? record[fieldname] : [],
  );
  const [values, setValues] = React.useState({
    loading: false,
    url: null,
    data: {},
    filename: null,
  });

  const beforeUpload = async (file) => {
    try {
      const resp = await app.actions.memberCreateImageRequest(file);
      setValues({
        ...values,
        url: resp.url,
        data: resp.fields,
        filename: resp.expected,
      });
    } catch (err) {
      console.warn('upload file failed', err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setValues({...values, loading: true});
    }
    if (info.file.status === 'done') {
      try {
        let nextValues = [...files];
        nextValues.push(values.filename);

        await app.actions.memberUpdateProfile({
          ...record,
          [fieldname]: nextValues,
        });

        setFiles(nextValues);
      } catch (err) {
        console.warn('update profile failed', err);
      }

      setValues({...values, loading: false});
    }
  };

  return (
    <Wrapper>
      <div className="files-container">
        {files?.map((file, index) => (
          <BgImage url={file} needMoreHeight={true} />
        ))}
      </div>

      <Upload
        name="file"
        listType="picture container"
        showUploadList={false}
        action={values.url}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        data={values.data}>
        <BgImage needMoreHeight={false}>
          {values.loading ? (
            <Spin />
          ) : (
            <Button icon={UploadOutlined}>上傳圖檔</Button>
          )}
        </BgImage>
      </Upload>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;

  & > .files-container {
    display: flex;
  }
`;

const BgImage = styled.div`
  margin: 10px;
  width: 100px;
  height: ${(props) => (props.needMoreHeight ? 100 : 32)}px;
  border-radius: 10px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${(props) => props.url || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & button {
    position: absolute;
    right: 0px;
    bottom: -4px;
  }
`;

function ImagesDisplayField(props) {
  let {fieldname, record} = props;
  return (
    <Wrapper>
      <div className="files-container">
        {record[fieldname] &&
          record[fieldname].map((file) => (
            <a target="_blank" rel="noreferrer" href={file}>
              <BgImage url={file} needMoreHeight={true} />
            </a>
          ))}
      </div>
    </Wrapper>
  );
}

export {ImagesEditField, ImagesDisplayField};
