import React from 'react';
import styled from 'styled-components';
import {Tabs, Input, Button} from 'antd';

import {
  EMPTY_EMAIL_TEMPALTE,
  INTERVIEW_EMAIL_TEMPLATE,
  ACCOUNT_CALL_EMAIL_TEMPLATE,
  RESUME_SAVE_ONLY_EMAIL_TEMPLATE,
} from '../../../content/email_template';
import * as AppContext from '../../AppContext';

function EmailDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onRefresh, onCancel} = props;
  const [values, setValues] = React.useState({
    ...record,
    interview: INTERVIEW_EMAIL_TEMPLATE(record, app.state.staff),
    other: {
      title: '',
      content: '',
    },
  });

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const onSendInterviewEmail = async () => {
    app.actions.setLoading(true);
    try {
      await app.actions.staffSendInterviewEmailById(
        values.owner,
        values.interview.title,
        values.interview.content,
      );
      onRefresh();
      onCancel();
    } catch (err) {
      throw err;
    }
    app.actions.setLoading(false);
  };

  const onSendManualEmail = async () => {
    app.actions.setLoading(true);
    try {
      await app.actions.staffSendManualEmailById(
        values.owner,
        values.other.title,
        values.other.content,
      );
      onCancel();
    } catch (err) {
      throw err;
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <section
        style={{
          paddingTop: 20,
        }}>
        <h3>郵件選單</h3>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="面試通知" key="1">
            <div className="field">
              <label>收件人</label>
              <Input disabled {...getInputProps('email')} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.interview.title}
                onChange={(e) =>
                  setValues({
                    ...values,
                    interview: {...values.interview, title: e.target.value},
                  })
                }
              />
            </div>
            <div className="field">
              <label>面試內文</label>
              <Input.TextArea
                value={values.interview.content}
                onChange={(e) =>
                  setValues({
                    ...values,
                    interview: {...values.interview, content: e.target.value},
                  })
                }
              />
            </div>

            <div className="action-bar">
              <Button
                type="ghost"
                onClick={onCancel}
                style={{minWidth: 125, height: 40, marginRight: 20}}>
                取消
              </Button>
              <Button
                type="primary"
                onClick={onSendInterviewEmail}
                style={{minWidth: 125, height: 40}}>
                送出
              </Button>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="其他通知" key="2">
            <div style={{marginBottom: 10}}>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    other: EMPTY_EMAIL_TEMPALTE(),
                  });
                }}>
                空白範本
              </Button>
              <Button
                style={{marginRight: 10}}
                onClick={() => {
                  setValues({
                    ...values,
                    other: RESUME_SAVE_ONLY_EMAIL_TEMPLATE(
                      record,
                      app.state.staff,
                    ),
                  });
                }}>
                面試錄取：僅先入資料庫範本
              </Button>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    other: ACCOUNT_CALL_EMAIL_TEMPLATE(record, app.state.staff),
                  });
                }}>
                帳戶資料催繳範本
              </Button>
            </div>
            <div className="field">
              <label>收件人</label>
              <Input disabled {...getInputProps('email')} />
            </div>
            <div className="field">
              <label>主旨</label>
              <Input
                value={values.other.title}
                onChange={(e) =>
                  setValues({
                    ...values,
                    other: {...values.other, title: e.target.value},
                  })
                }
              />
            </div>
            <div className="field">
              <label>內文</label>
              <Input.TextArea
                value={values.other.content}
                onChange={(e) =>
                  setValues({
                    ...values,
                    other: {...values.other, content: e.target.value},
                  })
                }
              />
            </div>

            <div className="action-bar">
              <Button
                type="ghost"
                onClick={onCancel}
                style={{minWidth: 125, height: 40, marginRight: 20}}>
                取消
              </Button>
              <Button
                type="primary"
                onClick={onSendManualEmail}
                style={{minWidth: 125, height: 40}}>
                送出
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    & > h3 {
      font-size: 24px;
      color: #4a4a4a;
      line-height: 22px;
    }

    & .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      width: 100%;

      & > label {
        width: 80px;
      }

      & > input {
        flex: 1;
      }

      & > textarea {
        flex: 1;

        height: 300px;
        resize: none;
        white-space: pre-wrap;
      }
    }

    & > .field:last-child {
      margin-bottom: 50px;
    }
  }

  & .action-bar {
    border-top: 1px solid #cccccc;
    padding-top: 20px;

    display: flex;
    justify-content: flex-end;

    & > button {
      margin-right: 15px;
    }

    & button:last-child {
      margin-right: 0px;
    }
  }
`;

export default EmailDetail;
