import React from 'react';
import styled from 'styled-components';
import {Button, Radio} from 'antd';

import {SEARCH_FIELDS} from '../../../content/resume';
import {STAFF_LABELS} from '../../../content/labels';
import * as AppContext from '../../AppContext';

function Filter(props) {
  const app = React.useContext(AppContext.Context);
  const {onSetRecords, onSetPendingRecords, onCancel} = props;
  const [values, setValues] = React.useState({...SEARCH_FIELDS});

  const getRadioProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const getButtonsArrayProps = (field, value, index) => {
    return {
      value: values[field],
      onClick: () => {
        let nextValues = [...values[field]];
        let findIndex = nextValues.findIndex((v) => v === value);

        if (findIndex === -1) {
          nextValues.push(value);
        } else {
          nextValues.splice(findIndex, 1);
        }

        setValues({...values, [field]: nextValues});
      },
    };
  };

  const onReset = () => {
    setValues({...SEARCH_FIELDS});
  };

  const onSearch = async () => {
    let keys = Object.keys(values);
    let nextValues = {...values};
    keys.forEach((key) => {
      if (nextValues[key] === '') {
        delete nextValues[key];
      }
    });

    app.actions.setLoading(true);
    try {
      let records = await app.actions.staffSearchMemberByFields(nextValues);
      onCancel();
      onSetRecords(records);
      onSetPendingRecords(records);
    } catch (err) {
      throw err;
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <h3>篩選條件</h3>

      <section>
        <div className="field">
          <label>性別</label>
          <Radio.Group buttonStyle="solid" {...getRadioProps('gender')}>
            <Radio.Button
              value={''}
              className="button-wrapper need-margin-right">
              不限
            </Radio.Button>
            <Radio.Button
              value={true}
              className="button-wrapper need-margin-right">
              男性
            </Radio.Button>
            <Radio.Button value={false} className="button-wrapper">
              女性
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>國籍</label>
          <Radio.Group buttonStyle="solid" {...getRadioProps('nationality')}>
            <Radio.Button value="" className="button-wrapper need-margin-right">
              不限
            </Radio.Button>
            <Radio.Button
              value={true}
              className="button-wrapper need-margin-right">
              本國籍
            </Radio.Button>
            <Radio.Button value={false} className="button-wrapper">
              外國籍
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>員工內部標籤</label>

          {STAFF_LABELS.map((label, index) => (
            <Button
              {...getButtonsArrayProps('STAFF_LABELS', label, index)}
              style={(() => {
                let selected =
                  values['STAFF_LABELS'].findIndex((value) => value === label) >
                  -1;

                return {
                  backgroundColor: selected ? '#E18135' : '#FFFFFF',
                  color: selected ? '#FFFFFF' : '#000000',
                };
              })()}
              className="button-wrapper need-margin-right need-margin-bottom">
              {label}
            </Button>
          ))}
        </div>
      </section>

      <div className="action-bar">
        <Button onClick={() => onReset()} style={{marginRight: 10}}>
          清除條件
        </Button>
        <Button type="primary" onClick={onSearch}>
          搜尋
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 30px 20px 20px;
    color: #4a4a4a;
    line-height: 22px;
  }

  & > section {
    border-bottom: 1px solid #ccc;
    padding: 10px 25px;

    & > .field {
      margin-bottom: 15px;

      & > label {
        width: 100px;
        margin-right: 20px;
        font-size: 14px;
        line-height: 42px;
      }

      & .button-wrapper {
        padding: 0px 12px;
        font-size: 12px;
      }

      & .need-margin-right {
        margin-right: 15px;
      }

      & .need-margin-bottom {
        margin-bottom: 15px;
      }
    }
  }

  & > .action-bar {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default Filter;
