import moment from 'moment';

function RepublicEraYearTransfer(date) {
  //date: 2021-04-04;
  let splitDate = date.split('-');
  let year = splitDate[0] - 1911;

  return `民國${year}年${splitDate[1]}月${splitDate[2]}日`;
}

function DaysDiffByYear(date1, date2) {
  //date1: 2021-04-04;
  let a = date1.split('-');
  let b = date2.split('-');

  return moment(a).diff(moment(b), 'years');
}

export {RepublicEraYearTransfer, DaysDiffByYear};
