import React from 'react';
import styled from 'styled-components';
import {Spin, Table, Tag} from 'antd';
import moment from 'moment';

import * as AppContext from '../../AppContext';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from '../../Widgets/Icon';

function SignupDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onCancel} = props;
  const [profile, setProfile] = React.useState({
    ...record,
  });
  const [records, setRecords] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      let signupList = await app.actions.staffGetParticipantListByUserId(
        profile.owner,
      );

      if (signupList.length > 0) {
        let activityIds = [...signupList].map((record) => record.activity);
        let activities = await app.actions.staffGetActivityListByIds(
          activityIds,
        );

        signupList = signupList.map((item, index) => {
          let findIndex = activities.findIndex(
            (activity) => activity.id === item.activity,
          );

          return {...item, activity_detail: {...activities[findIndex]}};
        });

        setRecords(signupList);
      }
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  }, [app.actions, profile.owner]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: '編號',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => {
        return <div>{record.activity.slice(0, 6)}</div>;
      },
      responsive: ['sm'],
    },
    {
      title: '活動名稱',
      dataIndex: 'name',
      key: 'name',
      render: (_, record, index) => {
        return <div>{record.activity_detail.name}</div>;
      },
    },
    {
      title: '活動日期',
      key: 'date',
      render: (_, record) => {
        return (
          <div>{`${moment(record.date_start).format('YYYY-MM-DD')} ~ ${moment(
            record.date_end,
          ).format('YYYY-MM-DD')}`}</div>
        );
      },
    },
    {
      title: '報名時段',
      key: 'date',
      render: (_, record, index) =>
        record.duration.map((item) => (
          <div style={{marginBottom: 15, whiteSpace: 'pre'}}>
            {item.date} {item.time[0]} - {item.time[1]}
          </div>
        )),
    },
    {
      title: '報名狀態',
      dataIndex: 'state',
      key: 'state',
      render: (_, record, index) => {
        return (
          <div style={{whiteSpace: 'pre-wrap'}}>
            {record.duration.map((item) => {
              switch (item.state) {
                case 'applied':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag
                        icon={<SyncOutlined color="blue" size={12} />}
                        color="blue">
                        已報名
                      </Tag>
                    </div>
                  );
                case 'qualified':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag
                        icon={<ClockCircleOutlined color="orange" size={12} />}
                        color="orange">
                        已錄取
                      </Tag>
                    </div>
                  );
                case 'unqualified':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag
                        icon={<CloseCircleOutlined color="red" size={12} />}
                        color="red">
                        未錄取
                      </Tag>
                    </div>
                  );
                case 'candidate':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag
                        icon={<MinusCircleOutlined color="purple" size={12} />}
                        color="purple">
                        備取
                      </Tag>
                    </div>
                  );
                case 'confirmed':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag
                        icon={<CheckCircleOutlined color="green" size={12} />}
                        color="green">
                        已確認
                      </Tag>
                    </div>
                  );
                case 'refused':
                  return (
                    <div style={{marginBottom: 15, display: 'flex'}}>
                      <Tag icon={<CloseCircleOutlined size={12} />}>已婉拒</Tag>
                    </div>
                  );
                default:
                  return `${item.state}\n`;
              }
            })}
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <h3>{profile.name} - 報名紀錄</h3>
      <div className="container">
        {loading ? <Spin /> : <Table columns={columns} dataSource={records} />}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 25px;
    color: #4a4a4a;
    line-height: 22px;
  }

  & > .container {
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default SignupDetail;
