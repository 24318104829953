import React from 'react';
import styled from 'styled-components';
import {Button, Radio, Input, Select} from 'antd';

import {STAFF_LABELS} from '../../../content/labels';
import * as AppContext from '../../AppContext';

function InterviewStatusDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onRefresh, onCancel} = props;
  const [values, setValues] = React.useState({
    ...record,
  });

  const getRadioGroupProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        if (e.target.value === 'sent' || e.target.value === 'confirmed') {
          setValues({...values});
        } else {
          setValues({...values, [field]: e.target.value});
        }
      },
    };
  };

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const onResetValue = () => {
    setValues({...record});
  };

  const onSubmit = async () => {
    app.actions.setLoading(true);
    try {
      let result = await app.actions.staffUpdateMemberDetailById(
        values.owner,
        values,
      );
      console.log('result', result);
      await onRefresh();
      onCancel();
    } catch (err) {
      throw err;
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <h3>{values.name} - 面試評價</h3>

      <section>
        <div className="field">
          <label>面試狀態</label>
          <Radio.Group
            {...getRadioGroupProps('INTERVIEW_STATE')}
            buttonStyle="solid">
            <Radio.Button value="pending" style={{fontSize: 12}}>
              未面試
            </Radio.Button>
            <Radio.Button
              value="sent"
              style={{
                cursor: 'not-allowed',
                fontSize: 12,
                color:
                  values.INTERVIEW_STATE === 'sent' ? '#FFFFFF' : '#BEBEBE',
              }}>
              面試通知已寄出
            </Radio.Button>
            <Radio.Button
              value="confirmed"
              style={{
                cursor: 'not-allowed',
                fontSize: 12,
                color:
                  values.INTERVIEW_STATE === 'confirmed'
                    ? '#FFFFFF'
                    : '#BEBEBE',
              }}>
              確認面試
            </Radio.Button>
            <Radio.Button
              value="rescheduled"
              style={{
                cursor: 'not-allowed',
                fontSize: 12,
                color: values.INTERVIEW_STATE === '' ? '#FFFFFF' : '#BEBEBE',
              }}>
              面試需改期
            </Radio.Button>
            <Radio.Button
              value="refused"
              style={{
                cursor: 'not-allowed',
                fontSize: 12,
                color: values.INTERVIEW_STATE === '' ? '#FFFFFF' : '#BEBEBE',
              }}>
              已不需面試
            </Radio.Button>
            <Radio.Button value="pass" style={{fontSize: 12}}>
              合格
            </Radio.Button>
            <Radio.Button value="candidate" style={{fontSize: 12}}>
              備取
            </Radio.Button>
            <Radio.Button value="unqualified" style={{fontSize: 12}}>
              不合格
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>員工內部標籤 (可複選)</label>
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            placeholder="請選擇標籤"
            defaultValue={values.STAFF_LABELS}
            onChange={(value) => {
              setValues({...values, STAFF_LABELS: value});
            }}>
            {STAFF_LABELS.map((label) => (
              <Select.Option value={label}>{label}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="field">
          <label>面試前備註</label>
          <Input.TextArea {...getInputProps('INTERVIEW_PRE_NOTE')} />
        </div>

        <div className="field">
          <label>面試備註</label>
          <Input.TextArea {...getInputProps('INTERVIEW_NOTE')} />
        </div>

        <div className="field">
          <label>優缺紀錄</label>
          <Input.TextArea {...getInputProps('NOTE')} />
        </div>
      </section>

      <div className="action-bar">
        <Button onClick={onResetValue} style={{marginRight: 20}}>
          重新填寫
        </Button>
        <Button onClick={onSubmit} type="primary">
          儲存送出
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 25px;
    color: #4a4a4a;
    line-height: 22px;
  }

  & > section {
    border-bottom: 1px solid #ccc;
    padding: 5px 25px;

    & > .field {
      margin-bottom: 10px;

      & > label {
        width: 100px;
        margin-right: 20px;
        font-size: 14px;
        line-height: 42px;
      }

      & .button-wrapper {
        padding: 0px 12px;
        font-size: 12px;
      }

      & .need-margin-right {
        margin-right: 15px;
      }

      & .need-margin-bottom {
        margin-bottom: 15px;
      }

      & > textarea {
        min-height: 60px;
        resize: none;
      }
    }
  }

  & > .action-bar {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default InterviewStatusDetail;
